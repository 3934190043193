import { Suspense } from 'react';

import { ErrorBoundary } from '@packages/shared';

import { cn } from '@packages/shared/src/utils/cn/cn';
import {
  DynamicInspiringSearchSuggestions,
  InspiringSearchSuggestionsLoading,
} from './InspiringSearchSuggestions';
import { DynamicSearchHistory, SearchHistoryLoading } from './SearchHistory';
import {
  DynamicSearchTermSuggestions,
  SearchTermSuggestionsLoading,
} from './SearchTermSuggestions';
import { useInspiringSearchSettings } from '../../useInspiringSearchSettings';
import type { SuggestionTrackingData } from './types';

export type SearchFieldPopupProps = {
  /** The current search term for suggestions */
  searchTerm?: string;
  /** 
   * Token for fetching data (will be removed when swr completely implements suspense support)
   @deprecated 
  */
  jwtToken: string;
  /** Callback that is called when the user clicks on any search suggestion */
  onAccept: (suggestionTrackingData: SuggestionTrackingData) => void;
  /** Callback that is called when the user clicks the arrow button to accept a search suggestion as the current input value without immediately triggering a navigation */
  onAdopt: (value: string) => void;
};

export const SearchFieldPopup = ({
  searchTerm,
  jwtToken,
  onAccept,
  onAdopt,
}: SearchFieldPopupProps) => {
  const { isInspiringSearchEnabled } = useInspiringSearchSettings();
  return (
    <div
      className={cn(
        `z-searchFieldPopup`, //  higher than the flyout navigation (filter popover is appbar which is 1100, appbar + 1 --> 1101 which flyout navigation)
        'bg-color-grey-light absolute left-0 top-full w-full py-0 shadow-sm',
        searchTerm && 'lg:w-[170%]', // it should not be md: otherwise the suggestion box will go off the viewport on smaller screens like (tabs, minitabs)
      )}
    >
      {!searchTerm && isInspiringSearchEnabled && (
        <ErrorBoundary>
          <Suspense fallback={<InspiringSearchSuggestionsLoading />}>
            <DynamicInspiringSearchSuggestions onAdopt={onAdopt} onAccept={onAccept} />
          </Suspense>
        </ErrorBoundary>
      )}
      {!searchTerm && (
        <ErrorBoundary>
          <Suspense fallback={<SearchHistoryLoading />}>
            <DynamicSearchHistory jwtToken={jwtToken} onAdopt={onAdopt} onAccept={onAccept} />
          </Suspense>
        </ErrorBoundary>
      )}
      {searchTerm && (
        <ErrorBoundary>
          <Suspense fallback={<SearchTermSuggestionsLoading />}>
            <DynamicSearchTermSuggestions
              jwtToken={jwtToken}
              onAccept={onAccept}
              searchTerm={searchTerm}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  );
};
