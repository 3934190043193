import { useEffect, useMemo } from 'react';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import { usePathname, useSearchParams } from 'next/navigation';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { getEndpoint } from '@packages/cms-components/src/utils/cmsFetcher';
import config, { getFullLocale } from '@packages/config';
import { fetchRequiredStaticJson } from '@packages/modules/src/Header/api';
import { ErrorBoundary, SnackbarProvider } from '@packages/shared';
import { userAgentDeviceCacheKey } from '@packages/shared/src/hooks/useUserAgentDevice/useUserAgentDevice';
import { LanguageProvider } from '@packages/shared/src/providers/LanguageProvider/LanguageProvider';
import type { GeoCountryCode, Language } from '@packages/config';
import { theme } from '@packages/themes';
import favicon from '@packages/themes/dist/favicon.ico';
import { Soasta } from '@packages/tracking/src/components/Soasta/Soasta';
import { TrackingProvider } from '@packages/tracking/src/components/TrackingProvider/TrackingProvider';
import { PageviewProvider } from '@packages/tracking/src/components/PageviewProvider/PageviewProvider';
import type { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { parseCookies } from 'nookies';
import { SWRConfig } from 'swr';
import { Provider as JotaiProvider } from 'jotai';
import { getNavigationDataEndpoint } from '@packages/modules/src/Header/Navigation/getNavigationDataEndpoint';
import { getLanguageFromUrl } from '@packages/shared/src/utils/getLanguageFromUrl/getLanguageFromUrl';
import type { Device } from '@packages/themes/src/default';
import { UrqlProvider } from '@packages/gql/src/urql';
import { PreviewHeaderContainer } from '@packages/cms-components/src/components/Preview/PreviewHead';
import { localizedPathnameCacheKey } from '@packages/config/src/default';
import type { CmsData } from '@packages/cms-components/interfaces';
import type { UspData } from '@packages/cms-components/src/modules/Usp/types';
import type { SurveyData } from '@packages/cms-components/src/modules/Survey/types';
import type { ContentSnippetApiData } from '@packages/cms-components/src/modules/PromotionBanner/types';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import { ConfigProvider } from '@packages/shared/src/providers/ConfigProvider/ConfigProvider';
import { CookieProvider } from '@packages/shared/src/providers/CookieProvider/CookieProvider';
import { logger } from '@packages/shared/src/utils/logger/logger';
import { SessionProvider } from '@packages/shared/src/providers/SessionProvider/SessionProvider';
import { createEmotionCache } from '@packages/shared/src/utils/createEmotionCache/createEmotionCache';
import { execParallel } from '@packages/shared/src/utils/execParallel/execParallel';
import { cached } from '@packages/shared/src/utils/cached/cached';
import { usePersistentScrid } from '@packages/shared/src/hooks/usePersistentScrid/usePersistentScrid';
import { isMonitoringCacheKey } from '@packages/shared/src/hooks/useIsMonitoring/useIsMonitoring';
import { sendWebVitals } from '@packages/shared/src/utils/sendWebVitals/sendWebVitals';
import { NotificationProvider } from '@packages/shared/src/providers/NotificationProvider/NotificationProvider';
import Script from 'next/script';
import { Snackbar } from '@packages/shared/src/components/Notification/Snackbar';
import '@packages/themes/dist/default-theme.css';
import { MediaProvider } from '@packages/shared/src/providers/MediaProvider/MediaProvider';
import { MouseMovementSender } from '@packages/shared/src/abtesting/useAbTesting/MouseMovementSender/MouseMovementSender';
import { getUspEndpoint } from '@packages/cms-components/src/utils/getUspEndpoint';
import { getSurveyEndpoint } from '@packages/cms-components/src/utils/getSurveyEndpoint';
import { getUspCacheKey } from '@packages/cms-components/src/utils/getUspCacheKey';
import { getCmsDataCacheKey } from '@packages/cms-components/src/utils/getCmsDataCacheKey';
import { getSurveyCacheKey } from '@packages/cms-components/src/utils/getSurveyCacheKey';
import { bucketFetcher } from '@packages/cms-components/src/utils/fetcher';
import Custom404 from './404';
import { getGeoCountryCode } from '../util/getGeoCountryCode';
import messages from '../../locales/compiled-messages';
import { PageLayout } from '../components/PageLayout';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface ShoppingAppProps extends AppProps<any> {
  emotionCache?: EmotionCache;
  device: Device;
  geoCountryCode?: GeoCountryCode;
  isBot: boolean;
  isPerformanceTest: boolean;
  isMonitoring: boolean;
  cookies: Record<string, string>;
  fallback: Record<string, any>;
}

const MyApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  device,
  geoCountryCode,
  isBot,
  isPerformanceTest,
  cookies,
  fallback,
}: ShoppingAppProps) => {
  const pathname = usePathname();
  const language = getLanguageFromUrl(pathname ?? '');
  const discardSsrCache = useSearchParams()?.get('discardSsrCache');
  const {
    i18n: { defaultLocale },
  } = useConfig();
  const locale = (language && getFullLocale(language as Language)) ?? defaultLocale;

  const { pathsToRenderChildrenOnly } = config.shoppingApp;
  const shouldRenderChildrenOnly = pathsToRenderChildrenOnly.some((path: string) =>
    pathname?.includes(path),
  );

  const { fallback: pageFallback, ...otherPageProps } = pageProps;

  const pageFallbackFinal = {
    ...pageFallback,
    // force reset of localizedPathname if not exist
    // used by LocaleSwitchSlot.tsx
    [localizedPathnameCacheKey]: pageFallback?.[localizedPathnameCacheKey] || null,
  };

  // theme construction is expensive, so we memoize it
  // on 6x CPU throttling this saves about 350ms per re-render
  const memoizedTheme = useMemo(() => theme(device), [device]);

  useEffect(() => {
    document.documentElement.lang = locale.substring(0, 2);
  }, [locale]);

  usePersistentScrid();

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        {/* NOTE: Without minimum-scale fixed positioning does not work in firefox in some cases, see e.g. https://stackoverflow.com/questions/77304399/why-position-fixed-does-not-work-on-mobile-without-minimum-scale */}
        <meta name="viewport" content="initial-scale=1, minimum-scale=1, width=device-width" />
        <link rel="shortcut icon" href={favicon.src} />
      </Head>
      {!isPerformanceTest && <Soasta soastaId={config.tracking.soastaId} />}
      {config.captcha.recaptchaSessionToken !== '' && (
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${config.captcha.recaptchaSessionToken}&waf=session`}
          async
          defer
        />
      )}
      {config.insiderPushNotifications.enabled && (
        <Script
          async
          src={`https://${config.insiderPushNotifications.partnerName}.api.useinsider.com/ins.js?id=${config.insiderPushNotifications.partnerId}`}
        />
      )}
      <CookieProvider cookies={cookies}>
        <ConfigProvider>
          <SessionProvider>
            <UrqlProvider pageProps={pageProps}>
              <LanguageProvider
                locale={locale}
                messages={messages[locale as keyof typeof messages]}
                defaultLocale={defaultLocale}
              >
                <TrackingProvider
                  tagmanagerId={config.tracking.tagmanagerId}
                  domain={config.tracking.tagmanagerDomain}
                  src={config.tracking.tagmanagerSrc}
                  isBot={isBot}
                  disableGTM={isPerformanceTest}
                >
                  <PageviewProvider>
                    <MediaProvider initialMedia={device}>
                      <ThemeProvider theme={memoizedTheme}>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <SWRConfig
                          value={{
                            fallback: discardSsrCache ? {} : { ...fallback, ...pageFallbackFinal },
                            dedupingInterval: 3000,
                          }}
                        >
                          <JotaiProvider>
                            {pageProps.isPreviewPage && <PreviewHeaderContainer />}
                            {shouldRenderChildrenOnly && (
                              <ErrorBoundary fallback={<Custom404 />}>
                                <Component {...otherPageProps} />
                              </ErrorBoundary>
                            )}
                            {!shouldRenderChildrenOnly && (
                              <PageLayout geoCountryCode={geoCountryCode}>
                                <NotificationProvider>
                                  <SnackbarProvider>
                                    <Snackbar />
                                    {/* TODO get useful fallback from UI/UX */}
                                    <ErrorBoundary fallback={<Custom404 />}>
                                      <Component {...otherPageProps} />
                                    </ErrorBoundary>
                                  </SnackbarProvider>
                                </NotificationProvider>
                              </PageLayout>
                            )}
                            <MouseMovementSender />
                          </JotaiProvider>
                        </SWRConfig>
                      </ThemeProvider>
                    </MediaProvider>
                  </PageviewProvider>
                </TrackingProvider>
              </LanguageProvider>
            </UrqlProvider>
          </SessionProvider>
        </ConfigProvider>
      </CookieProvider>
    </CacheProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const {
    query: { lang },
  } = ctx;
  const defaultLanguage = config.i18n.defaultLocale.split('-')[0];
  let fallback: Record<string, any> | undefined;

  const detectedDevice: Device = ctx.req?.headers['x-ua-device'] as Device;
  const isBot: boolean = ctx.req?.headers['x-ua-bot'] !== undefined;
  const isPerformanceTest: boolean = ctx.req?.headers['x-ua-performance'] !== undefined;
  const isMonitoring = ctx.req?.headers['x-ua-monitoring'] !== undefined;
  const cookies = parseCookies(ctx);
  const geoCountryCode: GeoCountryCode = getGeoCountryCode(ctx);

  if (ctx.req) {
    const {
      clientId,
      footer,
      navigation: { loadSliced },
    } = config;
    const language = (typeof lang === 'string' && lang) || defaultLanguage || 'de';
    const localeForCacheKey = getFullLocale(language);

    const navEndpoint = getNavigationDataEndpoint(
      clientId,
      detectedDevice === 'desktop' ? 'web' : 'mob',
      localeForCacheKey,
      loadSliced,
    );

    const uspEndpoint = getUspEndpoint(language);
    const footerCmsEndpoint = getEndpoint(footer.apiEndpoints.bucket, 'footer', language);
    const surveyEndpoint = getSurveyEndpoint(language);

    const uspCacheKey = getUspCacheKey(
      detectedDevice === 'desktop' ? 'web' : 'mob',
      localeForCacheKey,
    );

    const footerCacheKey = getCmsDataCacheKey(
      'footer',
      detectedDevice === 'desktop' ? 'web' : 'mob',
      localeForCacheKey,
    );

    const surveyCacheKey = getSurveyCacheKey(
      detectedDevice === 'desktop' ? 'web' : 'mob',
      localeForCacheKey,
    );

    const result = await execParallel([
      cached(navEndpoint, () => fetchRequiredStaticJson(navEndpoint, { componentName: '_app' })),
      cached(uspCacheKey, () => bucketFetcher<UspData>(uspEndpoint)()),
      cached(footerCacheKey, () => bucketFetcher<CmsData>(footerCmsEndpoint)()),
      cached(
        `nlSheetData-${language}`,
        async () => {
          try {
            return await bucketFetcher<ContentSnippetApiData | undefined>(
              config.nlSheetTexts.apiEndpoints.bucket.replace('<locale>', language.substring(0, 2)),
            )();
          } catch {
            logger.warn('no nlsheet data by drupal');
            return {};
          }
        },
        1000 * 60 * 5,
      ),
      /** As of today, the survey should be displayed on all pages except Checkout */
      cached(
        surveyCacheKey,
        () => bucketFetcher<SurveyData>(surveyEndpoint)(),
        1000 * 60 * 240, // 240 minutes
      ),
    ]);

    fallback = {
      [isMonitoringCacheKey]: isMonitoring,
      [userAgentDeviceCacheKey]: detectedDevice,
      [navEndpoint]: result[0],
      [uspCacheKey]: result[1],
      [footerCacheKey]: result[2],
      [`nlSheetData-${language}`]: result[3],
      [surveyCacheKey]: result[4],
    };
  }

  const appProps = await App.getInitialProps(appContext);

  return {
    ...appProps,
    fallback,
    device: detectedDevice,
    ...(!isBot && { geoCountryCode }),
    isBot,
    isPerformanceTest,
    isMonitoring,
    cookies,
  };
};

// Report web-vitals - see https://nextjs.org/docs/advanced-features/measuring-performance
export function reportWebVitals(metric: NextWebVitalsMetric) {
  sendWebVitals(metric);
}

// eslint-disable-next-line import/no-default-export
export default MyApp;
