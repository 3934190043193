import type { DynamicHelpConfig } from '../../default/cms/dynamicHelp.types';

export const dynamicHelp: DynamicHelpConfig = {
  labelMessageKey: 'forms.contact.dynamicSubject.howCanWeHelp',
  items: [
    { messageKeySelectOption: 'forms.contact.dynamicSubject.order' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.dataSecurity' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.website' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.customerAccount' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.personalData' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.praemie' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.productInfo' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.complaint.textileshoes' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.complaint.hometextile' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.complaint.furtherassortments' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.returnsProcessing' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.removeData' },
    // this will not go live until march
    // {
    //   messageKeySelectOption: 'forms.contact.dynamicSubject.reportBarrier',
    //   messageKeyAnswer: 'forms.contact.dynamicAnswer.reportBarrier',
    // },
  ],
};
